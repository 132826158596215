import React from "react";
import { Modal, Spinner, Button, Form } from "react-bootstrap";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { FiPlus } from "react-icons/fi";
import gql from "graphql-tag";
import { ME } from "../../graphql/queries";
import Select from "react-select";

const ModalHeader = Modal.Header as any;

const GET_QUICKBOOKS_CUSTOMERS = gql`
  query getQuickBooksCustomers($id: ID!) {
    getQuickbooksCustomersForUser(id: $id) {
      id
      displayName
    }
  }
`;

const GET_QUICKBOOKS_ITEMS = gql`
  query getQuickBooksItems($id: ID!) {
    getQuickbooksItemsForUser(id: $id) {
      id
      name
      type
      unitPrice
    }
  }
`;

const GET_QUICKBOOKS_DATA = gql`
  query getQuickbooksCreateInvoiceDataForUser($id: ID!) {
    getQuickbooksCreateInvoiceDataForUser(id: $id) {
      customers {
        id
        displayName
      }
      items {
        id
        name
        type
        unitPrice
      }
    }
  }
`;

const CREATE_DRAFT_INVOICE = gql`
  mutation createDraftQuickbooksInvoiceForPo($input: CreateInvoiceInput!) {
    createDraftQuickbooksInvoiceForPo(input: $input) {
      id
      invoiceId
    }
  }
`;

type Props = {
  poId: string;
  isShowing: boolean;
  onHide: () => void;
};

function PoCreateInvoiceModal(props: Props) {
  const { isShowing, onHide, poId } = props;
  const { data } = useQuery(ME);
  const userId = data?.me?.id;

  const { data: customersData, loading: customersLoading } = useQuery(
    GET_QUICKBOOKS_DATA,
    {
      variables: {
        id: userId,
      },
    }
  );
  const [createInvoice] = useMutation(CREATE_DRAFT_INVOICE);
  const [customerId, setCustomerId] = React.useState("");
  const [lineItems, setLineItems] = React.useState<any>([]);
  const [isSaving, setIsSaving] = React.useState(false);
  const [amount, setAmount] = React.useState(1);
  const [currentItemId, setCurrentItemId] = React.useState("");
  const [error, setError] = React.useState("");

  if (error) {
    return (
      <div>
        Uh oh - an unknown error occured. Please let us know via Intercom or
        email info@getvendorpal.com
      </div>
    );
  }

  if (customersLoading) {
    return (
      <div className="flex items-center">
        Loading Quickbooks data
        <Spinner animation="grow" className="ml-2"></Spinner>
      </div>
    );
  }

  const customerList =
    customersData?.getQuickbooksCreateInvoiceDataForUser?.customers || [];
  const itemsList =
    customersData?.getQuickbooksCreateInvoiceDataForUser?.items || [];

  const customersOptions = customerList.map((customer: any) => ({
    value: customer.id,
    label: customer.displayName,
  }));
  const itemsOptions = itemsList.map((item: any) => ({
    value: item.id,
    label: item.name,
  }));

  const handleAddItem = () => {
    setLineItems((prev: any) => {
      const itemData = itemsList.find((data: any) => data.id === currentItemId);
      // calculate the amount
      const { unitPrice, name } = itemData;
      let calculatedAmount = 0;
      if (unitPrice) {
        calculatedAmount = unitPrice * amount;
      }
      return [
        ...prev,
        {
          id: currentItemId,
          name,
          quantity: amount,
          amount: calculatedAmount,
        },
      ];
    });
  };

  const handleCreateInvoice = () => {
    setIsSaving(true);
    createInvoice({
      variables: {
        input: {
          poId,
          itemIds: lineItems.map((item: any) => item.id),
          itemAmounts: lineItems.map((item: any) => item.amount),
          itemQuantities: lineItems.map((item: any) => item.quantity),
          customerId: customerId,
        },
      },
    })
      .then((data) => {
        onHide();
      })
      .catch((e) => {
        setError(e.message);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <div>
      <div className="font-semibold mb-2">Quickbooks Customer:</div>
      <Select
        options={customersOptions}
        onChange={(option: any, action) => {
          if (option) {
            setCustomerId(option.value);
          }
        }}
      ></Select>
      <div className="font-semibold mt-4">Invoice Line Items: </div>
      {lineItems.length > 0 &&
        lineItems.map((item: any, index: any) => {
          return (
            <div key={index} className="flex items-center justify-between mt-2">
              <div>{item.name}</div>
              <div>{item.quantity}</div>
              <div>${item.amount}</div>
            </div>
          );
        })}
      <div className="flex items-center mt-2">
        <div style={{ flex: 4 }}>
          <Select
            className=""
            options={itemsOptions}
            onChange={(option: any) => setCurrentItemId(option.value)}
          ></Select>
        </div>
        <div style={{ flex: 2, marginLeft: 8 }}>
          <Form.Control
            value={amount}
            onChange={(e) => setAmount(parseInt(e.target.value))}
            className="flex-grow-0"
            type="number"
            placeholder="1"
          ></Form.Control>
        </div>
        <Button onClick={handleAddItem} className="flex-grow-0 ml-2">
          Add
        </Button>
      </div>
      <div className="mt-1 text-sm">
        You will be able to continue editing the invoice in Quickbooks.
      </div>
      <Button
        className="mt-4"
        variant="success"
        onClick={handleCreateInvoice}
        disabled={isSaving}
      >
        {isSaving ? "Creating..." : "Create Draft Invoice"}
      </Button>
    </div>
  );
}

export default PoCreateInvoiceModal;
