import React from "react";
import Iframe from "react-iframe";
import { Button } from "react-bootstrap";
import { SizeMe } from "react-sizeme";

function PoCratejoyCheckout() {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div>
      {isOpen ? (
        <div
          className="fixed"
          style={{
            top: 0,
            right: 440,
            width: 800,
            height: "100vh",
            zIndex: 10,
            backgroundColor: "#DBDBDB",
          }}
        >
          <SizeMe>
            {({ size }) => {
              return (
                <div className="relative p-8">
                  {/* <Iframe
              is="x-frame-bypass"
              url="https://news.ycombinator.com/"
              position="absolute"
              width="100%"
              id="myId"
              className="myClassname"
              height="100%"
              styles={{ height: "25px" }}
            />
             */}
                  <iframe
                    style={{
                      width: 700,
                      height: "90vh",
                    }}
                    is="x-frame-bypass"
                    src="https://subscribe.bitsbox.com/tools/index.html"
                  ></iframe>
                  <Button onClick={() => setIsOpen(false)} variant="primary">
                    Close
                  </Button>
                </div>
              );
            }}
          </SizeMe>
        </div>
      ) : (
        <Button variant="primary" onClick={() => setIsOpen(true)}>
          Cratejoy Checkout
        </Button>
      )}
    </div>
  );
}

export default PoCratejoyCheckout;
