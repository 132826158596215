import gql from "graphql-tag";

export const UPLOAD_PURCHASE_ORDER = gql`
  mutation UploadPurchaseOrder($id: ID!, $input: UploadPurchaseOrderInput!) {
    uploadPurchaseOrder(id: $id, input: $input) {
      id
    }
  }
`;

export const LOG_IN = gql`
  mutation LogIn($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        id
        email
      }
    }
  }
`;

export const SIGN_UP = gql`
  mutation SignUp($email: String!, $password: String!, $type: String!) {
    signup(email: $email, password: $password, type: $type) {
      token
      user {
        id
        email
      }
    }
  }
`;

export const FINALIZE_PURCHASE_ORDER = gql`
  mutation FinalizePurchaseOrder($id: ID!, $input: UploadPurchaseOrderInput!) {
    finalizePurchaseOrder(id: $id, input: $input) {
      id
    }
  }
`;
