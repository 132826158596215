import React from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Spinner, Button, Modal } from "react-bootstrap";
import PoCreateInvoiceModal from "./PoCreateInvoiceModal";
import { isProd } from "../../utils";

const ModalHeader: any = Modal.Header as any;

type Props = {
  poId: string;
};

const GET_INVOICE_DATA_FOR_PO = gql`
  query getPoInvoice($id: ID!) {
    purchaseOrder(id: $id) {
      id
      invoiceId
    }
  }
`;

function PoDetailInvoice(props: Props) {
  const { poId } = props;
  const { data, loading } = useQuery(GET_INVOICE_DATA_FOR_PO, {
    variables: {
      id: poId,
    },
  });
  const [showModal, setShowModal] = React.useState(false);

  const invoiceId = data?.purchaseOrder?.invoiceId;

  const handleCreateInvoice = () => {
    analytics.track("PO Create Invoice");

    setShowModal(true);
  };

  if (loading) {
    return <Spinner animation="border"></Spinner>;
  }

  if (showModal) {
    return (
      <PoCreateInvoiceModal
        poId={poId}
        isShowing={showModal}
        onHide={() => setShowModal(false)}
      ></PoCreateInvoiceModal>
    );
  }

  return (
    <div>
      {invoiceId ? (
        <a
          target="_blank"
          href={
            isProd()
              ? `https://c11.qbo.intuit.com/app/invoices?txnId=${invoiceId}`
              : `https://app.sandbox.qbo.intuit.com/app/invoice?txnId=${invoiceId}`
          }
        >
          See invoice in Quickbooks
        </a>
      ) : (
        <Button variant="primary" onClick={handleCreateInvoice}>
          Generate Quickbooks Invoice
        </Button>
      )}
    </div>
  );
}

export default PoDetailInvoice;
