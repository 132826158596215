import { useMutation, useQuery } from "@apollo/react-hooks";
import styled from "@emotion/styled";
import React, { useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { RouteComponentProps, useLocation, withRouter } from "react-router-dom";
import { LIGHT_SECONDARY_ONE } from "../colors";
import Input from "../components/shared/InputWithShortcuts";
import {
  FinalizePurchaseOrder,
  PurchaseOrder_purchaseOrder,
} from "../graphql/generated/types";
import { FINALIZE_PURCHASE_ORDER } from "../graphql/mutations";
import { PURCHASE_ORDER } from "../graphql/queries";
import { getFrontendUrl } from "../utils/urls";

type InnerProps = {
  purchaseOrder: PurchaseOrder_purchaseOrder;
};

function UpdatePOForm(props: InnerProps) {
  const firstInputRef = useRef();
  const [finalizePurchaseOrder] = useMutation<FinalizePurchaseOrder>(
    FINALIZE_PURCHASE_ORDER
  );

  const [parentFirstName, setParentFirstName] = useState(
    props.purchaseOrder.parentFirstName || ""
  );
  const [parentLastName, setParentLastName] = useState(
    props.purchaseOrder.parentLastName || ""
  );
  const [dateReceived, setDateReceived] = useState(
    props.purchaseOrder.dateReceived || ""
  );
  const [familyEmailAddress, setFamilyEmailAddress] = useState(
    props.purchaseOrder.familyEmailAddress || ""
  );
  const [studentFirstName, setStudentFirstName] = useState(
    props.purchaseOrder.studentFirstName || ""
  );
  const [studentLastName, setStudentLastName] = useState(
    props.purchaseOrder.studentLastName || ""
  );
  const [shippingAddress, setShippingAddress] = useState(
    props.purchaseOrder.shippingAddress || ""
  );
  const [usState, setUsState] = useState(props.purchaseOrder.usState || "");
  const [zip, setZip] = useState(props.purchaseOrder.zip || "");
  const [gradeLevel, setGradeLevel] = useState(
    props.purchaseOrder.gradeLevel || ""
  );
  const [startMonth, setStartMonth] = useState(
    props.purchaseOrder.startMonth || ""
  );
  const [numMonths, setNumMonths] = useState(
    props.purchaseOrder.numMonths || 0
  );

  const [schoolName, setSchoolName] = useState(
    props.purchaseOrder.schoolName || ""
  );

  const submitNewPO = async () => {
    await finalizePurchaseOrder({
      variables: {
        id: props.purchaseOrder.id,
        input: {
          parentFirstName,
          parentLastName,
          dateReceived,
          familyEmailAddress,
          studentFirstName,
          studentLastName,
          shippingAddress,
          usState,
          zip,
          gradeLevel,
          startMonth,
          schoolName,
          numMonths,
        },
      },
    });

    window.location.href =
      getFrontendUrl() + `/admin?user_id=${props.purchaseOrder.userId}`;
  };

  return (
    <Container>
      <InputTitle>Parent First Name</InputTitle>
      <Input
        ref={firstInputRef as any}
        value={parentFirstName}
        onChange={(e) => setParentFirstName(e.target.value)}
        shortcuts={[]}
        placeholder="Jane"
      />
      <InputTitle>Parent Last Name</InputTitle>
      <Input
        value={parentLastName}
        onChange={(e) => setParentLastName(e.target.value)}
        shortcuts={[]}
        placeholder="Doe"
      />
      <InputTitle>Date Received</InputTitle>
      <Input
        value={dateReceived}
        onChange={(e) => setDateReceived(e.target.value)}
        shortcuts={[]}
        placeholder="Some day"
      />
      <InputTitle>Family Email Address</InputTitle>
      <Input
        value={familyEmailAddress}
        onChange={(e) => setFamilyEmailAddress(e.target.value)}
        shortcuts={[]}
        placeholder="family@gmail.com"
      />
      <InputTitle>Student First Name</InputTitle>
      <Input
        value={studentFirstName}
        onChange={(e) => setStudentFirstName(e.target.value)}
        shortcuts={[]}
        placeholder="Jill"
      />
      <InputTitle>Student Last Name</InputTitle>
      <Input
        value={studentLastName}
        onChange={(e) => setStudentLastName(e.target.value)}
        shortcuts={[]}
        placeholder="Doe"
      />
      <InputTitle>Shipping Address</InputTitle>
      <Input
        value={shippingAddress}
        onChange={(e) => setShippingAddress(e.target.value)}
        shortcuts={[]}
        placeholder="544 Guerrero Street, Apt 3"
      />
      <InputTitle>State</InputTitle>
      <Input
        value={usState}
        onChange={(e) => setUsState(e.target.value)}
        shortcuts={[]}
        placeholder="CA"
      />
      <InputTitle>ZIP Code</InputTitle>
      <Input
        value={zip}
        onChange={(e) => setZip(e.target.value)}
        shortcuts={[]}
        placeholder="94110"
      />
      <InputTitle>Grade Level</InputTitle>
      <Input
        value={gradeLevel}
        onChange={(e) => setGradeLevel(e.target.value)}
        shortcuts={[]}
        placeholder="544 Guerrero Street, Apt 3"
      />
      <InputTitle>Start Month</InputTitle>
      <Input
        value={startMonth}
        onChange={(e) => setStartMonth(e.target.value)}
        shortcuts={[]}
        placeholder="CA"
      />
      <InputTitle>Number of Months</InputTitle>
      <Input
        value={numMonths}
        onChange={(e) => setNumMonths(e.target.value as any)}
        shortcuts={[]}
        placeholder="94110"
      />
      <InputTitle>School name</InputTitle>
      <Input
        value={schoolName}
        onChange={(e) => setSchoolName(e.target.value as any)}
        shortcuts={[]}
        placeholder="Heartwood"
      />
      <Button
        style={{ marginTop: 20, marginBottom: 200, width: "100%" }}
        onClick={submitNewPO}
      >
        Submit
      </Button>
    </Container>
  );
}

type OuterProps = {} & RouteComponentProps;

function FetchPOWrapper() {
  const query = new URLSearchParams(useLocation().search);
  const id = query.get("po_id");

  const { loading, error, data } = useQuery(PURCHASE_ORDER, {
    variables: {
      id: id,
    },
  });
  if (loading) {
    return <div>Loading</div>;
  } else if (error || !data || !data.purchaseOrder) {
    return <div>Something wrong</div>;
  }

  return <UpdatePOForm purchaseOrder={data.purchaseOrder} />;
}

export default withRouter(FetchPOWrapper);
/*
 * parents first name last name date it came in family emaila ddres, student first name, last name, shipping , state, zip, grade level, # of months, start date, last month
 */

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 800px;
  margin-left: 120px;
`;

const InputTitle = styled.div`
  display: flex;
  width: 100%;
  color: ${LIGHT_SECONDARY_ONE};
  margin-bottom: 6px;
  margin-top: 12px;
  font-size: 12px;
`;
