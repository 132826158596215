import "./App.scss";
import "./tailwind.output.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { ApolloProvider } from "@apollo/react-hooks";
import styled from "@emotion/styled";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { createUploadLink } from "apollo-upload-client";
import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AuthRoute from "./components/routes/AuthRoute";
import AdminListPage from "./containers/AdminListPage";
import CreateNewPOPage from "./containers/CreateNewPOPage";
import DashboardPage from "./containers/DashboardPage";
import LoginPage from "./containers/LoginPage";
import UpdatePOPage from "./containers/UpdatePOPage";
import { getBackendGraphqlUrl } from "./utils/urls";

import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const uploadLink = createUploadLink({
  uri: getBackendGraphqlUrl(),
  credentials: "same-origin",
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("cursiveauthtoken");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

export const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          // don't remove this log
          console.error(
            `[GraphQL error]: Message: ${JSON.stringify(
              message,
              null,
              2
            )}, Location: ${JSON.stringify(
              locations,
              null,
              2
            )}, Path: ${JSON.stringify(path, null, 2)}`
          )
        );
      // don't remove this log
      if (networkError)
        console.error(
          `[Network error]: ${JSON.stringify(networkError, null, 2)}`
        );
    }),
    authLink,
    uploadLink,
  ]),
  cache: new InMemoryCache({}),
});

const App: React.FC = () => {
  return (
    <AppContainer>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </ApolloProvider>
    </AppContainer>
  );
};

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/login" component={LoginPage}></Route>
      <Route exact path="/admin" component={AdminListPage} />
      <Route exact path="/create-new-po" component={CreateNewPOPage} />
      <Route exact path="/po" component={UpdatePOPage} />
      <AuthRoute path="/" component={DashboardPage} />
    </Switch>
  );
};

export default App;

const AppContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  color: black;
`;
