import React from "react";
import styled from "@emotion/styled";
import { FiX, FiSearch, FiEdit, FiCopy, FiTrash2 } from "react-icons/fi";
import { FaRegFilePdf } from "react-icons/fa";
import fuse from "fuse.js";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Spinner, Button, Form, Toast } from "react-bootstrap";
import moment from "moment";
import * as _ from "lodash";
import { Document, Page } from "react-pdf";
import copy from "copy-to-clipboard";
import PoDetailInvoice from "./po/PoDetailInvoice";
import { Link } from "react-router-dom";
import Iframe from "react-iframe";
import PoCratejoyCheckout from "./po/PoCratejoyCheckout";
import { isProd } from "../utils";
import produce from "immer";
import { ME } from "../graphql/queries";
import SaveCopyPasteInput from "./shared/SaveCopyPasteInput";
import Select from "react-select";

const DELETE_PO = gql`
  mutation deletePurchaseOrder($id: ID!) {
    deletePurchaseOrder(id: $id) {
      message
    }
  }
`;

const MARK_PO_DONE = gql`
  mutation markPoDone($id: ID!) {
    markPoDone(id: $id) {
      id
      isDone
    }
  }
`;

const SAVE_PO = gql`
  mutation updatePurchaseOrder($id: ID!, $input: UploadPurchaseOrderInput!) {
    updatePurchaseOrder(id: $id, input: $input) {
      id
      parentFirstName
      parentLastName
      studentFirstName
      studentLastName
      shippingAddressName
      careOfShipping
      shippingAddress
      shippingAddress2
      usState
      zip
      poNumber
      attachmentUrl
      dateReceived
      familyEmailAddress
      gradeLevel
      startMonth
      numMonths
      createdAt
    }
  }
`;

const ME_WITH_PO = gql`
  query CurrentUserWithPO {
    me {
      id
      purchaseOrders {
        id
        parentName
        studentName
        shippingAddressName
        careOfShipping
        shippingAddress
        shippingAddress2
        city
        usState
        zip
        isProcessing
        poNumber
        attachmentUrl
        dateReceived
        dateProcessed
        familyEmailAddress
        schoolName
        invoiceId
        gradeLevel
        startMonth
        numMonths
        isDone
        cratejoyAccount
        moxieQuantity
        moxieName
        moxieCoupon
      }
      integrations {
        id
        type
      }
    }
  }
`;

function App() {
  const [searchText, setSearchText] = React.useState("");
  const [currentPDFUrl, setCurrentPDFUrl] = React.useState("");
  const [currentPOId, setCurrentPOId] = React.useState("");
  const { data, loading } = useQuery(ME_WITH_PO, { pollInterval: 5000 });
  const [savePo] = useMutation(SAVE_PO);
  const [deletePo] = useMutation(DELETE_PO);
  const [markPoDone] = useMutation(MARK_PO_DONE);
  const [numPages, setNumPages] = React.useState(null);
  const [showCopiedToast, setShowCopiedToast] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [editingField, setEditingField] = React.useState("");
  const [poDate, setPoDate] = React.useState("");
  const [poNumber, setPoNumber] = React.useState("");
  const [studentName, setStudentName] = React.useState("");
  const [parentName, setParentName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [gradeLevel, setGradeLevel] = React.useState("");
  const [numMonths, setNumMonths] = React.useState(0);
  const [shippingAddressName, setShippingAddressName] = React.useState("");
  const [careOfShipping, setCareOfShipping] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [address2, setAddress2] = React.useState("");
  const [city, setCity] = React.useState("");
  const [state, setAddressState] = React.useState("");
  const [zipcode, setZipcode] = React.useState("");
  const [showDone, setShowDone] = React.useState(false);
  const [cratejoyAccount, setCratejoyAccount] = React.useState("");
  const [boxQuantity, setBoxQuantity] = React.useState("");
  const [boxName, setBoxName] = React.useState("");
  const [coupon, setCoupon] = React.useState("");
  const [defaultSearchFields, setDefaultSearchFields] = React.useState<
    string[]
  >(["poNumber", "schoolName", "studentName", "parentName"]);

  React.useEffect(() => {
    analytics.page("PurchaseOrders");
  }, []);

  const userId = data?.me?.id;
  const integrations = data?.me?.integrations || [];
  const isQuickbooksIntegrated = integrations.find(
    (integration: any) => integration.type === "QUICKBOOKS"
  );
  // all purchase orders
  const purchaseOrders = data?.me?.purchaseOrders || [];
  const donePos = purchaseOrders.filter((po: any) => po.isDone);
  const todoPos = purchaseOrders.filter((po: any) => !po.isDone);
  const currentFocusedPo = purchaseOrders.find(
    (po: any) => po.id === currentPOId
  );

  const fuseSearch = React.useMemo(() => {
    return new fuse(purchaseOrders, {
      keys: defaultSearchFields,
    });
  }, [purchaseOrders.length, defaultSearchFields]);

  const searchResults = fuseSearch.search(searchText);

  React.useEffect(() => {
    // clear editing
    setEditingField("");
    setCurrentPDFUrl("");
    if (currentFocusedPo) {
      setPoDate(currentFocusedPo.dateReceived || "");
      setPoNumber(currentFocusedPo.poNumber || "");
      setStudentName(currentFocusedPo.studentName || "");
      setParentName(currentFocusedPo.parentName || "");
      setEmail(currentFocusedPo.familyEmailAddress || "");
      setShippingAddressName(currentFocusedPo.shippingAddressName || "");
      setCareOfShipping(currentFocusedPo.careOfShipping || "");
      setAddress(currentFocusedPo.shippingAddress || "");
      setAddress2(currentFocusedPo.shippingAddress2 || "");
      setAddressState(currentFocusedPo.usState || "");
      setZipcode(currentFocusedPo.zip || "");
      setCity(currentFocusedPo.city || "");
      setGradeLevel(currentFocusedPo.gradeLevel || "");
      setNumMonths(currentFocusedPo.numMonths || 0);
      setCratejoyAccount(currentFocusedPo.cratejoyAccount || "");
      setBoxQuantity(currentFocusedPo.moxieQuantity || "");
      setBoxName(currentFocusedPo.moxieName || "");
      setCoupon(currentFocusedPo.moxieCoupon || "");
    }
  }, [currentPOId]);

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  const handleSavePO = () => {
    setIsSaving(true);
    savePo({
      variables: {
        id: currentPOId,
        input: {
          dateReceived: poDate,
          poNumber: poNumber,
          studentName,
          parentName,
          familyEmailAddress: email,
          shippingAddressName,
          careOfShipping,
          shippingAddress: address,
          shippingAddress2: address2,
          usState: state,
          zip: zipcode,
          city,
          gradeLevel,
          numMonths: numMonths,
          cratejoyAccount,
          moxieQuantity: parseInt(boxQuantity),
          moxieName: boxName,
          moxieCoupon: coupon,
        },
      },
    })
      .then((result) => {
        setEditingField("");
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const handleDeleteCurrentPo = () => {
    if (window.confirm("Are you sure you want to delete this PO?")) {
      deletePo({
        variables: {
          id: currentPOId,
        },
        update: (cache, result) => {
          const data = cache.readQuery<any>({
            query: ME_WITH_PO,
          });
          const nextState = produce(data, (draftState: any) => {
            draftState.me.purchaseOrders = draftState.me.purchaseOrders.filter(
              (po: any) => po.id !== currentPOId
            );
          });
          cache.writeQuery({
            query: ME_WITH_PO,
            data: nextState,
          });
          setCurrentPOId("");
        },
      }).then(() => {});
    }
  };

  const handlePoDone = () => {
    setIsSaving(true);
    markPoDone({
      variables: {
        id: currentPOId,
      },
      optimisticResponse: {
        __typename: "Mutation",
        markPoDone: {
          __typename: "PurchaseOrder",
          id: currentPOId,
          isDone: true,
        },
      },
    }).finally(() => {
      setIsSaving(false);
    });
  };

  const handleChangeSearch = (options: any) => {
    const { value } = options;
    if (value === "all") {
      setDefaultSearchFields([
        "poNumber",
        "schoolName",
        "studentName",
        "parentName",
      ]);
    } else {
      setDefaultSearchFields([value]);
    }
  };

  if (loading) {
    return (
      <div className="p-12">
        <Spinner animation="border"></Spinner>
      </div>
    );
  }

  const processingPOs = purchaseOrders.filter((po: any) => po.isProcessing);
  const completePOs = purchaseOrders.filter((po: any) => !po.isProcessing);
  const completePOSortedByDate = _.orderBy(
    completePOs,
    (o: any) => {
      if (o.dateProcessed) {
        return moment(o.dateProcessed).unix();
      } else {
        return 0;
      }
    },
    ["desc"]
  );

  const renderProcessingPOs = () => {
    return (
      <>
        <div className="text-sm mb-3">
          <div className="flex items-center">
            <div className="mr-1">Scanning</div>
            <Spinner size={"sm"} animation="border"></Spinner>
          </div>
        </div>
        <div className="bg-white flex-grow mb-4">
          <TableRowHeader className="px-4 py-2">
            <TableHeader>Date Received</TableHeader>
          </TableRowHeader>
          {processingPOs.map((data: any) => {
            return (
              <TableRow
                key={data.id}
                className="p-4"
                onClick={() => {
                  window.open(data.attachmentUrl, "_blank");
                }}
              >
                <TableCell>
                  {moment(data.createdAt).format(
                    "dddd, MMMM Do YYYY, h:mm:ss a"
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </div>
      </>
    );
  };

  const renderCurrentPdfViewer = () => {
    if (!currentPDFUrl || currentPDFUrl === "") {
      return null;
    }
    return (
      <div
        className="fixed"
        style={{
          top: 0,
          right: 440,
          width: 800,
          height: "100vh",
          zIndex: 10,
          backgroundColor: "#DBDBDB",
        }}
      >
        <div className="relative p-8">
          <Document
            style={{ height: "100%", width: "100%" }}
            file={currentPDFUrl}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <div style={{ height: "90vh", overflow: "scroll" }}>
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  width={700}
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                />
              ))}
            </div>
          </Document>
          <Button onClick={() => setCurrentPDFUrl("")} variant="primary">
            Close
          </Button>
        </div>
      </div>
    );
  };

  const renderPos = () => {
    // if searching returno nly serach results
    if (searchText !== "") {
      return (
        <>
          <div className="text-sm mb-3">Search Results:</div>
          <div className="bg-white flex-grow">
            <TableRowHeader className="px-4 py-2">
              <TableHeader>Date Processed</TableHeader>
              <TableHeader>School</TableHeader>
              <TableHeader>Student</TableHeader>
              {/* <TableHeader>Product</TableHeader> */}
            </TableRowHeader>
            {searchResults
              .map((result: any) => result.item)
              .map((data: any) => {
                return (
                  <TableRow
                    key={data.id}
                    className="p-4"
                    onClick={() => setCurrentPOId(data.id)}
                  >
                    <TableCell>
                      {data.dateProcessed
                        ? moment(data.dateProcessed).fromNow()
                        : "Unknown"}
                    </TableCell>
                    <TableCell>{data.schoolName}</TableCell>
                    <TableCell>{data.studentName}</TableCell>
                    {/* <TableCell>{data.productName}</TableCell> */}
                  </TableRow>
                );
              })}
          </div>
        </>
      );
    }
    // otherwise show the right pos
    if (showDone) {
      return (
        <div className="bg-white flex-grow">
          <TableRowHeader className="px-4 py-2">
            <TableHeader>Date Processed</TableHeader>
            <TableHeader>School</TableHeader>
            <TableHeader>Student</TableHeader>
            {/* <TableHeader>Product</TableHeader> */}
          </TableRowHeader>
          {donePos.map((data: any) => {
            return (
              <TableRow
                key={data.id}
                className="p-4"
                onClick={() => setCurrentPOId(data.id)}
              >
                <TableCell>
                  {data.dateProcessed
                    ? moment(data.dateProcessed).fromNow()
                    : "Unknown"}
                </TableCell>
                <TableCell>{data.schoolName}</TableCell>
                <TableCell>{data.studentName}</TableCell>
                {/* <TableCell>{data.productName}</TableCell> */}
              </TableRow>
            );
          })}
        </div>
      );
    }
    return (
      <>
        {processingPOs.length > 0 && renderProcessingPOs()}
        <div className="text-sm mb-3">Processed</div>
        <div className="bg-white flex-grow">
          <TableRowHeader className="px-4 py-2">
            <TableHeader>Date Processed</TableHeader>
            <TableHeader>School</TableHeader>
            <TableHeader>Student</TableHeader>
            {/* <TableHeader>Product</TableHeader> */}
          </TableRowHeader>
          {todoPos.map((data: any) => {
            return (
              <RowContainer key={data.id}>
                <PureRow
                  key={data.id}
                  className="px-4 pt-4"
                  onClick={() => setCurrentPOId(data.id)}
                >
                  <TableCell>
                    {data.dateProcessed
                      ? moment(data.dateProcessed).fromNow()
                      : "Unknown"}
                  </TableCell>
                  <TableCell>{data.schoolName}</TableCell>
                  <TableCell>{data.studentName}</TableCell>
                  {/* <TableCell>{data.productName}</TableCell> */}
                </PureRow>
                <div className="px-4 pb-4 flex items-align">
                  {data.invoiceId && (
                    <div
                      style={{
                        marginTop: 4,
                        marginRight: 8,
                        color: "#74C1EA",
                        border: "1px solid #DFE1E5",
                        borderRadius: "4px",
                        padding: "2px 4px",
                        fontSize: "12px",
                      }}
                    >
                      Invoice Created
                    </div>
                  )}
                  {data.cratejoyAccount && (
                    <div
                      style={{
                        marginTop: 4,
                        marginRight: 8,
                        color: "#74C1EA",
                        border: "1px solid #DFE1E5",
                        borderRadius: "4px",
                        padding: "2px 4px",
                        fontSize: "12px",
                      }}
                    >
                      Cratejoy Account Linked
                    </div>
                  )}
                </div>
              </RowContainer>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <div className="relative">
      <div className="p-8 text-3xl border-b border-color-gray-100 bg-white">
        Purchase Orders
      </div>
      <div style={{ position: "absolute", top: 40, right: 32 }}>
        <Toast
          onClose={() => setShowCopiedToast(false)}
          show={showCopiedToast}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <strong className="mr-auto">Notifications</strong>
            <small>Now</small>
          </Toast.Header>
          <Toast.Body>Copied text to clipboard!</Toast.Body>
        </Toast>
      </div>
      {renderCurrentPdfViewer()}
      <div className="mx-8 mt-4">
        <div className="flex items-center mb-4">
          <div
            onClick={() => setShowDone(false)}
            className="cursor-pointer mr-2"
            style={{ fontWeight: showDone ? "normal" : "bold" }}
          >
            In Progress ({todoPos.length})
          </div>
          <div
            onClick={() => setShowDone(true)}
            className="cursor-pointer"
            style={{ fontWeight: !showDone ? "normal" : "bold" }}
          >
            Done ({donePos.length})
          </div>
        </div>
        <div className="flex items-center mb-4">
          <div
            style={{ flex: 5 }}
            className="bg-white px-4 py-2 border flex items-center"
          >
            <FiSearch style={{ marginRight: 12 }}></FiSearch>
            <input
              className="flex-grow outline-none"
              type="text"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          <div style={{ flex: 2 }} className="flex items-center">
            <div className="mx-2">Search Across:</div>
            <div style={{ flex: 1 }}>
              <Select
                isClearable={false}
                onChange={handleChangeSearch}
                style={{ flex: 1 }}
                defaultValue={{ value: "all", label: "All Fields" }}
                options={[
                  { value: "all", label: "All Fields" },
                  { value: "poNumber", label: "PO #" },
                  { value: "studentName", label: "Student Name" },
                ]}
              ></Select>
            </div>
          </div>
        </div>
        <div className="flex">
          <div className="flex-grow">{renderPos()}</div>
          {currentFocusedPo && (
            <div
              className="bg-white ml-4"
              style={{
                width: 400,
                position: "sticky",
                top: 0,
                maxHeight: "100vh",
                overflow: "scroll",
                paddingBottom: 40,
              }}
            >
              <div className="border-b border-color-gray-100 p-4 flex justify-between">
                <div className="">{currentFocusedPo.poNumber}</div>
                <div className="flex items-align">
                  <FiTrash2
                    style={{ cursor: "pointer", marginRight: 8 }}
                    onClick={handleDeleteCurrentPo}
                  ></FiTrash2>
                  <FiX
                    style={{ cursor: "pointer" }}
                    onClick={() => setCurrentPOId("")}
                  ></FiX>
                </div>
              </div>
              <div className="p-4">
                {currentFocusedPo.attachmentUrl && (
                  <div>
                    <div
                      className="border border-color-gray-100 px-4 py-2 rounded flex items-center cursor-pointer"
                      onClick={() => {
                        // window.open(currentFocusedPo.attachmentUrl)
                        analytics.track("PO Open PDF");
                        setCurrentPDFUrl(currentFocusedPo.attachmentUrl);
                      }}
                    >
                      <div className="mr-4">
                        <FaRegFilePdf></FaRegFilePdf>
                      </div>
                      <div className="flex-grow font-semibold">View PDF</div>
                    </div>
                  </div>
                )}
                <div>
                  <div
                    style={{ color: "#8B8B8B" }}
                    className="py-3 font-semibold"
                  >
                    Details
                  </div>
                  <div className="mb-3">
                    <div className="font-semibold">Date Processed</div>
                    <div className="flex items-center">
                      <div>
                        {currentFocusedPo.dateProcessed
                          ? moment(data.dateProcessed).format("MM/DD/YYYY")
                          : "Unknown"}
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="font-semibold">PO Date</div>
                    <SaveCopyPasteInput
                      isEditing={editingField === "poDate"}
                      isSaving={isSaving}
                      onChange={(e: any) => setPoDate(e.target.value)}
                      onEdit={() => setEditingField("poDate")}
                      value={poDate}
                      onSave={handleSavePO}
                      onCopied={() => setShowCopiedToast(true)}
                    ></SaveCopyPasteInput>
                  </div>
                  <div className="mb-3">
                    <div className="font-semibold">Student Name(s)</div>
                    <SaveCopyPasteInput
                      isEditing={editingField === "studentName"}
                      isSaving={isSaving}
                      onChange={(e: any) => setStudentName(e.target.value)}
                      onEdit={() => setEditingField("studentName")}
                      value={studentName}
                      onSave={handleSavePO}
                      onCopied={() => setShowCopiedToast(true)}
                    ></SaveCopyPasteInput>
                  </div>
                  <div className="mb-3">
                    <div className="font-semibold">Parent Name</div>
                    <SaveCopyPasteInput
                      isEditing={editingField === "parentName"}
                      isSaving={isSaving}
                      onChange={(e: any) => setParentName(e.target.value)}
                      onEdit={() => setEditingField("parentName")}
                      value={parentName}
                      onSave={handleSavePO}
                      onCopied={() => setShowCopiedToast(true)}
                    ></SaveCopyPasteInput>
                  </div>
                  <div className="mb-3">
                    <div className="font-semibold">Email Address</div>
                    <SaveCopyPasteInput
                      isEditing={editingField === "email"}
                      isSaving={isSaving}
                      onChange={(e: any) => setEmail(e.target.value)}
                      onEdit={() => setEditingField("email")}
                      value={email}
                      onSave={handleSavePO}
                      onCopied={() => setShowCopiedToast(true)}
                    ></SaveCopyPasteInput>
                  </div>
                  <div className="mb-3">
                    <div className="font-semibold">Grade Level</div>
                    <SaveCopyPasteInput
                      isEditing={editingField === "gradeLevel"}
                      isSaving={isSaving}
                      onChange={(e: any) => setGradeLevel(e.target.value)}
                      onEdit={() => setEditingField("gradeLevel")}
                      value={gradeLevel}
                      onSave={handleSavePO}
                      onCopied={() => setShowCopiedToast(true)}
                    ></SaveCopyPasteInput>
                  </div>
                  <div className="mb-3">
                    <div className="font-semibold">Subscription # Months</div>
                    <div className="flex items-center">
                      {editingField === "numMonths" ? (
                        <>
                          <Form.Control
                            as="select"
                            value={numMonths}
                            onChange={(e) =>
                              setNumMonths(parseInt(e.target.value))
                            }
                          >
                            <option value={0}>0</option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                            <option value={11}>11</option>
                            <option value={12}>12</option>
                          </Form.Control>
                          <Button
                            onClick={handleSavePO}
                            disabled={isSaving}
                            className="ml-2"
                            variant="success"
                          >
                            {isSaving ? "Saving..." : "Save"}
                          </Button>
                        </>
                      ) : (
                        <>
                          <div className="">{currentFocusedPo.numMonths}</div>
                          <HighlightIcon
                            className="ml-1 p-2"
                            onClick={() => setEditingField("numMonths")}
                          >
                            <FiEdit></FiEdit>
                          </HighlightIcon>
                          <HighlightIcon
                            className="p-2"
                            onClick={() => {
                              copy(currentFocusedPo.numMonths);
                              setShowCopiedToast(true);
                            }}
                          >
                            <FiCopy></FiCopy>
                          </HighlightIcon>
                        </>
                      )}
                    </div>
                  </div>
                  {(!isProd() ||
                    userId === "e3d2692a-8ca9-485a-b6fe-a2c8b284dc0a") && (
                    <>
                      <div className="mb-3">
                        <div className="font-semibold">Box Quantity</div>
                        <SaveCopyPasteInput
                          isEditing={editingField === "moxieQuantity"}
                          isSaving={isSaving}
                          onChange={(e: any) => setBoxQuantity(e.target.value)}
                          onEdit={() => setEditingField("moxieQuantity")}
                          value={boxQuantity}
                          onSave={handleSavePO}
                          onCopied={() => setShowCopiedToast(true)}
                        ></SaveCopyPasteInput>
                      </div>
                      <div className="mb-3">
                        <div className="font-semibold">Individual Box Name</div>
                        <SaveCopyPasteInput
                          isEditing={editingField === "moxieName"}
                          isSaving={isSaving}
                          onChange={(e: any) => setBoxName(e.target.value)}
                          onEdit={() => setEditingField("moxieName")}
                          value={boxName}
                          onSave={handleSavePO}
                          onCopied={() => setShowCopiedToast(true)}
                        ></SaveCopyPasteInput>
                      </div>
                      <div className="mb-3">
                        <div className="font-semibold">Coupon/Special Code</div>
                        <SaveCopyPasteInput
                          isEditing={editingField === "moxieCoupon"}
                          isSaving={isSaving}
                          onChange={(e: any) => setCoupon(e.target.value)}
                          onEdit={() => setEditingField("moxieCoupon")}
                          value={coupon}
                          onSave={handleSavePO}
                          onCopied={() => setShowCopiedToast(true)}
                        ></SaveCopyPasteInput>
                      </div>
                    </>
                  )}

                  <div
                    style={{ color: "#8B8B8B" }}
                    className="py-3 font-semibold"
                  >
                    Shipping
                  </div>
                  <div className="mb-3">
                    <div className="font-semibold">Shipping To:</div>
                    <div className="flex items-center">
                      {editingField === "shippingAddressName" ? (
                        <>
                          <Form.Control
                            value={shippingAddressName}
                            onChange={(e) =>
                              setShippingAddressName(e.target.value)
                            }
                            type="text"
                          ></Form.Control>
                          <Button
                            onClick={handleSavePO}
                            disabled={isSaving}
                            className="ml-2"
                            variant="success"
                          >
                            {isSaving ? "Saving..." : "Save"}
                          </Button>
                        </>
                      ) : (
                        <>
                          <div className="">
                            {currentFocusedPo.shippingAddressName}
                          </div>
                          <HighlightIcon
                            className="ml-1 p-2"
                            onClick={() =>
                              setEditingField("shippingAddressName")
                            }
                          >
                            <FiEdit></FiEdit>
                          </HighlightIcon>
                          <HighlightIcon
                            className="p-2"
                            onClick={() => {
                              copy(currentFocusedPo.shippingAddressName);
                              setShowCopiedToast(true);
                            }}
                          >
                            <FiCopy></FiCopy>
                          </HighlightIcon>
                        </>
                      )}
                    </div>
                  </div>
                  {currentFocusedPo.careOfShipping && (
                    <div className="mb-3">
                      <div className="font-semibold">C/O</div>
                      <div className="flex items-center">
                        {editingField === "shippingCo" ? (
                          <>
                            <Form.Control
                              value={careOfShipping}
                              onChange={(e) =>
                                setCareOfShipping(e.target.value)
                              }
                              type="text"
                            ></Form.Control>
                            <Button
                              onClick={handleSavePO}
                              disabled={isSaving}
                              className="ml-2"
                              variant="success"
                            >
                              {isSaving ? "Saving..." : "Save"}
                            </Button>
                          </>
                        ) : (
                          <>
                            <div className="">
                              {currentFocusedPo.careOfShipping}
                            </div>
                            <HighlightIcon
                              className="ml-1 p-2"
                              onClick={() => setEditingField("shippingCo")}
                            >
                              <FiEdit></FiEdit>
                            </HighlightIcon>
                            <HighlightIcon
                              className="p-2"
                              onClick={() => {
                                copy(currentFocusedPo.careOfShipping);
                                setShowCopiedToast(true);
                              }}
                            >
                              <FiCopy></FiCopy>
                            </HighlightIcon>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="mb-3">
                    <div className="font-semibold">Address 1</div>
                    <div className="flex items-center">
                      {editingField === "shippingAddress" ? (
                        <>
                          <Form.Control
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            type="text"
                          ></Form.Control>
                          <Button
                            onClick={handleSavePO}
                            disabled={isSaving}
                            className="ml-2"
                            variant="success"
                          >
                            {isSaving ? "Saving..." : "Save"}
                          </Button>
                        </>
                      ) : (
                        <>
                          <div className="">
                            {currentFocusedPo.shippingAddress}
                          </div>
                          <HighlightIcon
                            className="ml-1 p-2"
                            onClick={() => setEditingField("shippingAddress")}
                          >
                            <FiEdit></FiEdit>
                          </HighlightIcon>
                          <HighlightIcon
                            className="p-2"
                            onClick={() => {
                              copy(currentFocusedPo.shippingAddress);
                              setShowCopiedToast(true);
                            }}
                          >
                            <FiCopy></FiCopy>
                          </HighlightIcon>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="font-semibold">Address 2</div>
                    <div className="flex items-center">
                      {editingField === "shippingAddress2" ? (
                        <>
                          <Form.Control
                            value={address2}
                            onChange={(e) => setAddress2(e.target.value)}
                            type="text"
                          ></Form.Control>
                          <Button
                            onClick={handleSavePO}
                            disabled={isSaving}
                            className="ml-2"
                            variant="success"
                          >
                            {isSaving ? "Saving..." : "Save"}
                          </Button>
                        </>
                      ) : (
                        <>
                          <div className="">
                            {currentFocusedPo.shippingAddress2}
                          </div>
                          <HighlightIcon
                            className="ml-1 p-2"
                            onClick={() => setEditingField("shippingAddress2")}
                          >
                            <FiEdit></FiEdit>
                          </HighlightIcon>
                          <HighlightIcon
                            className="p-2"
                            onClick={() => {
                              copy(currentFocusedPo.shippingAddress2);
                              setShowCopiedToast(true);
                            }}
                          >
                            <FiCopy></FiCopy>
                          </HighlightIcon>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="font-semibold">City</div>
                    <div className="flex items-center">
                      {editingField === "city" ? (
                        <>
                          <Form.Control
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            type="text"
                          ></Form.Control>
                          <Button
                            onClick={handleSavePO}
                            disabled={isSaving}
                            className="ml-2"
                            variant="success"
                          >
                            {isSaving ? "Saving..." : "Save"}
                          </Button>
                        </>
                      ) : (
                        <>
                          <div className="">{currentFocusedPo.city}</div>
                          <HighlightIcon
                            className="ml-1 p-2"
                            onClick={() => setEditingField("city")}
                          >
                            <FiEdit></FiEdit>
                          </HighlightIcon>
                          <HighlightIcon
                            className="p-2"
                            onClick={() => {
                              copy(currentFocusedPo.city);
                              setShowCopiedToast(true);
                            }}
                          >
                            <FiCopy></FiCopy>
                          </HighlightIcon>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="font-semibold">State</div>
                    <div className="flex items-center">
                      {editingField === "state" ? (
                        <>
                          <Form.Control
                            value={state}
                            onChange={(e) => setAddressState(e.target.value)}
                            type="text"
                          ></Form.Control>
                          <Button
                            onClick={handleSavePO}
                            disabled={isSaving}
                            className="ml-2"
                            variant="success"
                          >
                            {isSaving ? "Saving..." : "Save"}
                          </Button>
                        </>
                      ) : (
                        <>
                          <div className="">{currentFocusedPo.usState}</div>
                          <HighlightIcon
                            className="ml-1 p-2"
                            onClick={() => setEditingField("state")}
                          >
                            <FiEdit></FiEdit>
                          </HighlightIcon>
                          <HighlightIcon
                            className="p-2"
                            onClick={() => {
                              copy(currentFocusedPo.usState);
                              setShowCopiedToast(true);
                            }}
                          >
                            <FiCopy></FiCopy>
                          </HighlightIcon>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="font-semibold">Zipcode</div>
                    <div className="flex items-center">
                      {editingField === "zipcode" ? (
                        <>
                          <Form.Control
                            value={zipcode}
                            onChange={(e) => setZipcode(e.target.value)}
                            type="text"
                          ></Form.Control>
                          <Button
                            onClick={handleSavePO}
                            disabled={isSaving}
                            className="ml-2"
                            variant="success"
                          >
                            {isSaving ? "Saving..." : "Save"}
                          </Button>
                        </>
                      ) : (
                        <>
                          <div className="">{currentFocusedPo.zip}</div>
                          <HighlightIcon
                            className="ml-1 p-2"
                            onClick={() => setEditingField("zipcode")}
                          >
                            <FiEdit></FiEdit>
                          </HighlightIcon>
                          <HighlightIcon
                            className="p-2"
                            onClick={() => {
                              copy(currentFocusedPo.zip);
                              setShowCopiedToast(true);
                            }}
                          >
                            <FiCopy></FiCopy>
                          </HighlightIcon>
                        </>
                      )}
                    </div>
                  </div>

                  {(!isProd() ||
                    userId === "0e60a86d-2a3d-4346-a67b-1fd6d9559ced") && (
                    <div>
                      <div
                        style={{ color: "#8B8B8B" }}
                        className="py-3 font-semibold"
                      >
                        Cratejoy
                      </div>
                      <PoCratejoyCheckout></PoCratejoyCheckout>
                      <div className="font-semibold mt-2">Cratejoy Account</div>
                      <SaveCopyPasteInput
                        isEditing={editingField === "cratejoyAccount"}
                        isSaving={isSaving}
                        onChange={(e: any) =>
                          setCratejoyAccount(e.target.value)
                        }
                        onEdit={() => setEditingField("cratejoyAccount")}
                        value={cratejoyAccount}
                        onSave={handleSavePO}
                        onCopied={() => setShowCopiedToast(true)}
                      ></SaveCopyPasteInput>
                    </div>
                  )}
                  {(!isProd() ||
                    userId === "e3d2692a-8ca9-485a-b6fe-a2c8b284dc0a") && (
                    <div>
                      <div
                        style={{ color: "#8B8B8B" }}
                        className="py-3 font-semibold"
                      >
                        Google Sheets
                      </div>
                      <Button variant="primary" disabled={true}>
                        Import as row into Google Sheets
                      </Button>
                    </div>
                  )}
                  <div
                    style={{ color: "#8B8B8B" }}
                    className="py-3 font-semibold"
                  >
                    Quickbooks
                  </div>
                  {isQuickbooksIntegrated ? (
                    <PoDetailInvoice poId={currentPOId}></PoDetailInvoice>
                  ) : (
                    <div>
                      To quickly generate invoices from POs,{" "}
                      <Link to="/settings">
                        please set up our Quickbooks integration in Settings.
                      </Link>
                    </div>
                  )}
                  {!currentFocusedPo.isDone && (
                    <>
                      <div
                        className="my-4"
                        style={{ borderTop: "1px solid #E7E7E7" }}
                      ></div>
                      <div className="">
                        <Button
                          variant="success"
                          onClick={handlePoDone}
                          disabled={isSaving}
                        >
                          Mark PO as done
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const HighlightIcon = styled.div`
  cursor: pointer;
  padding: 8px;
  color: #cfd3d7;
  &:hover {
    background-color: #edf2f6;
  }
`;

const TableHeader = styled.div`
  flex: 1;
  text-align: left;
  color: #8c8c8c;
`;

const TableRow = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  border-bottom: 1px solid #f3f3f3;
  cursor: pointer;
  &:hover {
    background-color: #f3f3f3;
  }
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #f3f3f3;
  cursor: pointer;
  &:hover {
    background-color: #f3f3f3;
  }
`;

const PureRow = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
`;

const TableRowHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  color: #8b8b8b;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f3f3f3;
`;

const TableCell = styled.div`
  flex: 1;
  color: black;
`;

export default App;
