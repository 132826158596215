import styled from "@emotion/styled";
import isHotkey from "is-hotkey";
import * as React from "react";
import {
  DARK_SECONDARY_DARK,
  DARK_SECONDARY_LIGHT,
  LIGHT_SECONDARY_ONE,
  LIGHT_SECONDARY_THREE,
  Theme,
} from "../../colors";

type Shortcut = {
  keys: string;
  callback: () => any;
};

type Props = {
  shortcuts: Shortcut[];
  border?: boolean;
  forwardRef?: React.Ref<HTMLInputElement>;
  colorTheme?: Theme;
  type?: string;
  style?: any;
} & React.InputHTMLAttributes<HTMLInputElement>;

type State = {};

class InputWithShortcuts extends React.Component<Props, State> {
  inputRef = React.createRef<HTMLInputElement>();

  map: any = {};

  select = () => {
    this.inputRef.current?.select();
  };

  focus = () => {
    this.inputRef.current?.focus();
  };

  onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    for (const index in this.props.shortcuts) {
      const keys = this.props.shortcuts[index].keys;
      const callback = this.props.shortcuts[index].callback;
      if (isHotkey(keys, event as any)) {
        event.preventDefault();
        callback();
      }
    }
  };

  render() {
    const { border, type } = this.props;

    return (
      <Input
        {...this.props}
        ref={this.inputRef}
        onKeyDown={this.onKeyDown}
        colorTheme={this.props.colorTheme}
      />
    );
  }
}

export default InputWithShortcuts;

const Input = styled.input<{ colorTheme: any }>`
  width: 100%;
  -webkit-appearance: none;
  background-image: none;
  border-radius: 4px;
  color: ${(props) =>
    props.colorTheme === Theme.DARK
      ? DARK_SECONDARY_LIGHT
      : LIGHT_SECONDARY_ONE};
  border: 0.5px solid rgb(239, 239, 239);
  :focus {
    outline: none;
  }
  ::placeholder {
    color: ${(props) =>
      props.colorTheme === Theme.DARK
        ? DARK_SECONDARY_DARK
        : LIGHT_SECONDARY_THREE};
  }
  padding: 10px 12px;
  font-size: 14px;
`;
