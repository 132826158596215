import { useMutation } from "@apollo/react-hooks";
import styled from "@emotion/styled";
import React, { useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useLocation, withRouter } from "react-router-dom";
import { LIGHT_SECONDARY_ONE } from "../colors";
import Input from "../components/shared/InputWithShortcuts";
import { UPLOAD_PURCHASE_ORDER } from "../graphql/mutations";
import { getFrontendUrl } from "../utils/urls";

function CreateNewPOPage(props: any) {
  const query = new URLSearchParams(useLocation().search);
  const id = query.get("user_id");
  const firstInputRef = useRef();
  const [uploadPurchaseOrder] = useMutation(UPLOAD_PURCHASE_ORDER);

  const [parentFirstName, setParentFirstName] = useState("");
  const [parentLastName, setParentLastName] = useState("");
  const [dateReceived, setDateReceived] = useState("");
  const [familyEmailAddress, setFamilyEmailAddress] = useState("");
  const [studentFirstName, setStudentFirstName] = useState("");
  const [studentLastName, setStudentLastName] = useState("");
  const [shippingAddress, setShippingAddress] = useState("");
  const [usState, setUsState] = useState("");
  const [zip, setZip] = useState("");
  const [gradeLevel, setGradeLevel] = useState("");
  const [startMonth, setStartMonth] = useState("");
  const [numMonths, setNumMonths] = useState(0);

  const submitNewPO = async () => {
    await uploadPurchaseOrder({
      variables: {
        id: id,
        input: {
          parentFirstName,
          parentLastName,
          dateReceived,
          familyEmailAddress,
          studentFirstName,
          studentLastName,
          shippingAddress,
          usState,
          zip,
          gradeLevel,
          startMonth,
          numMonths,
        },
      },
    });

    window.location.href = getFrontendUrl() + `/admin?user_id=${id}`;
    // setParentFirstName("");
    // setParentLastName("");
    // setDateReceived("");
    // setFamilyEmailAddress("");
    // setStudentFirstName("");
    // setStudentLastName("");
    // setShippingAddress("");
    // setUsState("");
    // setZip("");
    // setGradeLevel("");
    // setStartMonth("");
    // setNumMonths(0);
    // if (firstInputRef && firstInputRef.current) {
    //   (firstInputRef as any).current.focus();
    // }
  };

  return (
    <Container>
      <InputTitle>Parent First Name</InputTitle>
      <Input
        ref={firstInputRef as any}
        value={parentFirstName}
        onChange={(e) => setParentFirstName(e.target.value)}
        shortcuts={[]}
        placeholder="Jane"
      />
      <InputTitle>Parent Last Name</InputTitle>
      <Input
        value={parentLastName}
        onChange={(e) => setParentLastName(e.target.value)}
        shortcuts={[]}
        placeholder="Doe"
      />
      <InputTitle>Date Received</InputTitle>
      <Input
        value={dateReceived}
        onChange={(e) => setDateReceived(e.target.value)}
        shortcuts={[]}
        placeholder="Some day"
      />
      <InputTitle>Family Email Address</InputTitle>
      <Input
        value={familyEmailAddress}
        onChange={(e) => setFamilyEmailAddress(e.target.value)}
        shortcuts={[]}
        placeholder="family@gmail.com"
      />
      <InputTitle>Student First Name</InputTitle>
      <Input
        value={studentFirstName}
        onChange={(e) => setStudentFirstName(e.target.value)}
        shortcuts={[]}
        placeholder="Jill"
      />
      <InputTitle>Student Last Name</InputTitle>
      <Input
        value={studentLastName}
        onChange={(e) => setStudentLastName(e.target.value)}
        shortcuts={[]}
        placeholder="Doe"
      />
      <InputTitle>Shipping Address</InputTitle>
      <Input
        value={shippingAddress}
        onChange={(e) => setShippingAddress(e.target.value)}
        shortcuts={[]}
        placeholder="544 Guerrero Street, Apt 3"
      />
      <InputTitle>State</InputTitle>
      <Input
        value={usState}
        onChange={(e) => setUsState(e.target.value)}
        shortcuts={[]}
        placeholder="CA"
      />
      <InputTitle>ZIP Code</InputTitle>
      <Input
        value={zip}
        onChange={(e) => setZip(e.target.value)}
        shortcuts={[]}
        placeholder="94110"
      />
      <InputTitle>Grade Level</InputTitle>
      <Input
        value={gradeLevel}
        onChange={(e) => setGradeLevel(e.target.value)}
        shortcuts={[]}
        placeholder="K-2"
      />
      <InputTitle>Start Month</InputTitle>
      <Input
        value={startMonth}
        onChange={(e) => setStartMonth(e.target.value)}
        shortcuts={[]}
        placeholder="9"
      />
      <InputTitle>Number of Months</InputTitle>
      <Input
        value={numMonths}
        onChange={(e) => setNumMonths(e.target.value as any)}
        shortcuts={[]}
        placeholder="3"
      />
      <Button
        style={{ marginTop: 20, marginBottom: 200, width: "100%" }}
        onClick={submitNewPO}
      >
        Submit
      </Button>
    </Container>
  );
}

export default withRouter(CreateNewPOPage);

/*
 * parents first name last name date it came in family emaila ddres, student first name, last name, shipping , state, zip, grade level, # of months, start date, last month
 */

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 800px;
  margin-left: 120px;
`;

const InputTitle = styled.div`
  display: flex;
  width: 100%;
  color: ${LIGHT_SECONDARY_ONE};
  margin-bottom: 6px;
  margin-top: 12px;
  font-size: 12px;
`;
