import styled from "@emotion/styled";
import React from "react";
import { Switch, Route, useRouteMatch, Link } from "react-router-dom";
import PurchaseOrders from "../components/PurchaseOrders";
import Settings from "../components/Settings";

function MainPage(props: any) {
  let match = useRouteMatch();

  const {
    location: { pathname },
  } = props;
  let currentPage;
  switch (pathname) {
    case "/settings":
      currentPage = "settings";
      break;
    default:
      currentPage = "purchaseorders";
  }
  const [activeTab, setActiveTab] = React.useState(currentPage);
  return (
    <div className="App">
      <div
        style={{
          position: "fixed",
          width: 252,
          top: 0,
          left: 0,
          height: "100vh",
          borderRight: "1px solid rgb(243, 243, 243)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div className="flex  flex-col px-12 py-8 ">
          <div className="">
            <div className="font-bold text-xl mb-4">Vendorpal</div>
            <SidebarTab
              active={activeTab === "purchaseorders"}
              onClick={() => setActiveTab("purchaseorders")}
            >
              {activeTab === "purchaseorders" && (
                <div className="absolute w-1 h-full bg-black -ml-12"></div>
              )}
              <Link style={{ textDecoration: "none" }} to="/">
                <div>Purchase Orders</div>
              </Link>
            </SidebarTab>
          </div>
        </div>
        <div className="flex  flex-col px-12 py-8 border-top">
          <SidebarTab>
            <Link style={{ textDecoration: "none" }} to="/settings">
              Settings
            </Link>
          </SidebarTab>
        </div>
      </div>
      <div
        style={{
          marginLeft: 252,
          minHeight: "100vh",
          backgroundColor: "rgb(249, 249, 249)",
        }}
      >
        <Switch>
          <Route path={`/settings`} component={Settings}></Route>
          <Route path="/">
            <PurchaseOrders></PurchaseOrders>
          </Route>
        </Switch>
        {/* {activeTab === "purchaseorders" && <PurchaseOrders></PurchaseOrders>} */}
      </div>
    </div>
  );
}

const SidebarTab = styled.div<any>`
  position: relative;
  padding: 8px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${(props) => (props.active ? "black" : "#737373")};
  cursor: pointer;
`;

export default MainPage;
