import { useMutation, useQuery } from "@apollo/react-hooks";
import styled from "@emotion/styled";
import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { LIGHT_NEGATIVE_ONE, LIGHT_SECONDARY_ONE } from "../colors";
import InputWithShortcuts from "../components/shared/InputWithShortcuts";
import { CurrentUser, LogIn } from "../graphql/generated/types";
import { LOG_IN } from "../graphql/mutations";
import { ME } from "../graphql/queries";
import LoadingScreen from "../components/LoadingScreen";
import qs from "query-string";

type Props = {} & RouteComponentProps;

function LoginPage(props: Props) {
  const {
    location: { search },
  } = props;

  const [logIn] = useMutation<LogIn>(LOG_IN);

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");

  const {
    loading: meLoading,
    error: meError,
    data: meData,
    refetch,
  } = useQuery<CurrentUser>(ME);

  React.useEffect(() => {
    if (search) {
      const parsed = qs.parse(search);
      const { token } = parsed;
      // login with pasred token
      if (token) {
        localStorage.setItem("cursiveauthtoken", token as string);
        window.location.replace("/");
        refetch();
      }
    }
  }, [search]);

  const redirectToLanding = () => {
    props.history.push("/");
  };

  const attemptLogIn = async () => {
    try {
      const payload = await logIn({
        variables: {
          email,
          password,
        },
      });

      if (payload?.data?.login?.token) {
        localStorage.setItem("cursiveauthtoken", payload.data.login.token);
        window.location.reload();
      } else {
        setError("incorrect login");
        return;
      }
    } catch (e) {
      setError("incorrect login");
      return;
    }
  };

  if (meLoading) {
    return <LoadingScreen />;
  } else if (meData?.me) {
    return <Redirect to="/" />;
  }

  return (
    <Container>
      <Header>
        <Wrapper>
          <HeaderContent></HeaderContent>
        </Wrapper>
      </Header>
      <LoginContainer>
        <CTAText>Welcome back.</CTAText>
        <InputTitle>Email</InputTitle>
        <InputWithShortcuts
          placeholder="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          shortcuts={[
            {
              keys: "enter",
              callback: attemptLogIn,
            },
          ]}
          style={{ marginBottom: "20px" }}
        />
        <InputTitle>Password</InputTitle>
        <InputWithShortcuts
          placeholder="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          shortcuts={[
            {
              keys: "enter",
              callback: attemptLogIn,
            },
          ]}
          style={{ marginBottom: "20px" }}
          type="password"
        />
        <Button onClick={attemptLogIn}>Login</Button>
        {error && <Error>{error}</Error>}
      </LoginContainer>
    </Container>
  );
}

export default withRouter(LoginPage);

const Error = styled.div`
  text-align: center;
  color: ${LIGHT_NEGATIVE_ONE};
  margin-top: 20px;
`;

const CTAText = styled.div`
  display: flex;
  justify-content: center;
  font-size: 24px;
  margin-bottom: 24px;
  font-weight: bold;
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  height: 100%;
  padding: 10px 20px;
  background-color: rgb(89, 130, 237);
  color: #ffffff;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  width: 100%;
  margin-top: 12px;
`;

const InputTitle = styled.div`
  color: ${LIGHT_SECONDARY_ONE};
  margin-bottom: 12px;
`;

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 426px;
  margin: 0 auto;
  padding: 0 16px;
`;

const LogoTitle = styled.div`
  cursor: pointer;
  font-size: 24px;
`;

const EthosButton = styled.div`
  cursor: pointer;
  margin-left: auto;
`;

const AboutButton = styled.div`
  cursor: pointer;
  margin-left: 32px;
`;

const Container = styled.div``;

const Header = styled.div`
  position: relative;
  z-index: 3;
`;

const Wrapper = styled.div`
  width: 100vw;
  max-width: 1172px;
  margin: 0 auto;
  padding: 0 16px;
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100px;
`;
