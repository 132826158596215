import { isProd } from "./index";

export function getBackendGraphqlUrl() {
  return isProd()
    ? "https://prowess-back-office-backend.herokuapp.com/graphql"
    : "http://localhost:4000/graphql";
}

export function getBackendUrl() {
  return isProd()
    ? "https://prowess-back-office-backend.herokuapp.com"
    : "http://localhost:4000";
}

export function getBackendSubscriptionsUrl() {
  return isProd()
    ? "wss://prowess-back-office-backend.herokuapp.com/graphql"
    : "ws://localhost:4000/graphql";
}

export function getFrontendUrl() {
  return isProd()
    ? "https://www.prowessbackoffice.com"
    : "http://localhost:3000";
}
