import React from "react";
import styled from "@emotion/styled";
import { Form, Button } from "react-bootstrap";
import { FiEdit, FiCopy } from "react-icons/fi";
import copy from "copy-to-clipboard";

type Props = {
  isEditing?: boolean;
  isSaving?: boolean;
  onChange: any;
  onEdit: any;
  value: any;
  onSave: any;
  onCopied: any;
};

function SaveCopyPasteInput(props: Props) {
  const {
    isEditing,
    onChange,
    value,
    onSave,
    isSaving,
    onEdit,
    onCopied,
  } = props;
  return (
    <div>
      <div className="flex items-center">
        {isEditing ? (
          <>
            <Form.Control
              value={value}
              onChange={onChange}
              type="text"
            ></Form.Control>
            <Button
              onClick={onSave}
              disabled={isSaving}
              className="ml-2"
              variant="success"
            >
              {isSaving ? "Saving..." : "Save"}
            </Button>
          </>
        ) : (
          <>
            <div className="">{value}</div>
            <HighlightIcon className="ml-1 p-2" onClick={onEdit}>
              <FiEdit></FiEdit>
            </HighlightIcon>
            <HighlightIcon
              className="p-2"
              onClick={() => {
                copy(value);
                onCopied();
              }}
            >
              <FiCopy></FiCopy>
            </HighlightIcon>
          </>
        )}
      </div>
    </div>
  );
}

const HighlightIcon = styled.div`
  cursor: pointer;
  padding: 8px;
  color: #cfd3d7;
  &:hover {
    background-color: #edf2f6;
  }
`;

export default SaveCopyPasteInput;
