import gql from "graphql-tag";

export const ME = gql`
  query CurrentUser {
    me {
      id
      email
    }
  }
`;

export const USER = gql`
  query User($id: ID) {
    user(id: $id) {
      id
      email
      purchaseOrders {
        id
        isProcessing
      }
    }
  }
`;

export const USERS = gql`
  query Users {
    users {
      id
      email
    }
  }
`;

export const PURCHASE_ORDER = gql`
  query PurchaseOrder($id: ID!) {
    purchaseOrder(id: $id) {
      id
      userId
      parentFirstName
      parentLastName
      dateReceived
      familyEmailAddress
      studentFirstName
      studentLastName
      shippingAddress
      usState
      zip
      gradeLevel
      startMonth
      numMonths
      schoolName
      isProcessing
    }
  }
`;
