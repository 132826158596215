import { useQuery } from "@apollo/react-hooks";
import styled from "@emotion/styled";
import React from "react";
import { RouteComponentProps, useLocation, withRouter } from "react-router-dom";
import { LIGHT_SECONDARY_ONE, LIGHT_TERTIARY_TWO } from "../colors";
import { User, Users } from "../graphql/generated/types";
import { USER, USERS } from "../graphql/queries";
import { getFrontendUrl } from "../utils/urls";

type Props = {} & RouteComponentProps;

function AdminListPage(props: Props) {
  const query = new URLSearchParams(useLocation().search);
  const userId = query.get("user_id");

  const {
    loading: usersLoading,
    error: usersError,
    data: usersData,
  } = useQuery<Users>(USERS);

  const { loading, error, data } = useQuery<User>(USER, {
    variables: {
      id: userId,
    },
  });

  if (loading || usersLoading) {
    return <div>Loading</div>;
  } else if (!data || error || !usersData || usersError) {
    return <div>Error</div>;
  }

  if (!userId && usersData && !data.user) {
    return (
      <Container>
        <InputTitle>Admin Panel</InputTitle>
        {usersData.users.map((user) => {
          return (
            <div
              onClick={() => {
                window.location.href =
                  getFrontendUrl() + `/admin?user_id=${user.id}`;
              }}
            >
              {user.id} {user.email}
            </div>
          );
        })}
      </Container>
    );
  }
  if (!data.user) {
    return <div>???</div>;
  }
  return (
    <Container>
      <InputTitle>Admin Panel for {data.user!.email}</InputTitle>
      <button
        style={{
          marginTop: 12,
          marginBottom: 12,
          backgroundColor: LIGHT_TERTIARY_TWO,
          borderRadius: 8,
          padding: 4,
        }}
        onClick={() => {
          window.location.href =
            getFrontendUrl() + `/create-new-po?user_id=${userId}`;
        }}
      >
        Create new PO
      </button>
      {data
        .user!.purchaseOrders.sort((a, b) =>
          a.isProcessing === b.isProcessing ? 0 : a.isProcessing ? -1 : 1
        )
        .map((purchaseOrder) => {
          return (
            <div
              onClick={() => {
                window.location.href =
                  getFrontendUrl() + `/po?po_id=${purchaseOrder.id}`;
              }}
            >
              {purchaseOrder.id}{" "}
              {purchaseOrder.isProcessing ? "Incomplete" : "Processed"}
            </div>
          );
        })}
    </Container>
  );
}

export default withRouter(AdminListPage);

/*
 * parents first name last name date it came in family emaila ddres, student first name, last name, shipping , state, zip, grade level, # of months, start date, last month
 */

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 800px;
  margin-left: 120px;
`;

const InputTitle = styled.div`
  display: flex;
  color: ${LIGHT_SECONDARY_ONE};
  margin-bottom: 6px;
  margin-top: 12px;
  font-size: 24px;
`;
